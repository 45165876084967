<template>
    <div class="col-sm-12 card column" id="tab1">
        <form class="form-inline" v-on:submit.prevent="updateProcess" id="form2" action="/version" method="post">
            <div class="row">
                <div style="text-align: start; padding:10px;">
                    <h2>Update</h2><br>
                    <p>Please fill in this form to update a service.</p>
                    <hr>
                </div>

                <div class="col-sm-12">
                    <h4>Basic service information</h4><br>

                    <label>State:</label>
                    <select class="form-control" v-model="input.state">
                        <option value="true">Activate</option>
                        <option value="false">Deactivate</option>
                    </select><br>

                    <label for="name">Name:</label>
                    <input type="text" name="name" class="form-control"
                        style="background-color:#484848; color: #fff; width: 100%;" v-model="input.newName" required
                        placeholder="Enter service name"><br>

                    <label for="family">Family:</label>
                    <input type="text" name="family" class="form-control"
                        style="background-color:#484848; color: #fff; width: 100%;" v-model="input.newFamily" required
                        placeholder="Enter family"><br>

                    <button type="submit" id="SendButton" name='Send2' value="Send2" class="btn btn-primary mb-2 mt-4"
                        style="width: 100%;"><i id="loaderSend2"></i>
                        <h5>Update</h5>
                    </button><br>
                    <hr>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: "EditService",

    data: function () {
        return {
            id: '',
            input: {
                newName: '',
                newFamily: '',
                state: "true"
            },
        }
    },
    methods: {
        async getServiceDetail() {
            let response = await fetch(`https://gpt-tools-00ax.onrender.com/api/v1/services/${this.id}`, {
                mode: "cors",
                credentials: "include",
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (response.status == 200) {
                response = await response.json();

                this.input.newName = response.name;
                this.input.newFamily = response.family;
                this.input.state = response.is_active;
            } else {

            }
        },

        async updateProcess() {
            let data = {};

            data.name = this.input.newName;
            data.family = this.input.newFamily;
            data.is_active = (this.input.state === "true");

            try {
                let response = await fetch(`https://gpt-tools-00ax.onrender.com/api/v1/services/${this.id}`, {
                    mode: "cors",
                    credentials: "include",
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                });

                if (response.status == 200) {
                    alert("Service successfully updated!");
                    this.getServiceDetail();
                }
            } catch (error) {
                alert("Error updating service. Please try again.");
            }
        }
    },

    beforeMount() {
        this.id = this.$route.params.serviceId;
        this.getServiceDetail();
    }
}
</script>

<style>
* {
    color: var(--text-color);
}

.card {
    background-color: #323232;
    box-shadow: 1px 1px 5px rgb(255, 255, 255);
    text-align: left;
}

textarea {
    background-color: #484848 !important;
    border: 1px solid;
    color: #fff !important;

}

.column {
    width: 80%;
    margin: 0px auto;
}

/* Media query for small screens */
@media screen and (max-width: 500px) {
    .column {
        width: 100%;
        display: block;
        margin: 10px 20px;
        right: 20px;
    }
}

.row {
    margin: 0 0px 13px 0px;
}

.row:after {
    display: table;
    clear: both;
}

.btn {
    background: #5c759e;
    border-color: #fff;
    border-radius: 50px;
    font-size: small;
    font-weight: bold;
}
</style>