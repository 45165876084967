<template>
    <div class="container">
        <div class="login-card">
            <h2>Login</h2>
            <h3>Enter your credentials</h3>
            <form v-on:submit.prevent="processLogInUser" class="login-form">
                <div class="username">
                    <input spellcheck="false" class="control" type="text" v-model="user.username" placeholder="Username"
                        v-on:keyup="handleChange" v-on:keydown="handleStartTyping" />
                    <div id="spinner" class="spinner"></div>
                </div>

                <input spellcheck="false" class="control" id="password" type="password" v-model="user.password"
                    placeholder="Password" />
                <button class="control" type="submit">LOGIN</button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "LogIn",

    data: function () {
        return {
            user: {
                username: "",
                password: ""
            }
        }
    },
    methods: {

        handleStartTyping() {
            const spinner = document.getElementById("spinner");
            spinner.classList.add("visible");
        },

        handleChange() {
            const spinner = document.getElementById("spinner");
            setTimeout(() => {
                spinner.classList.remove("visible");
            }, 1000);
        },

        async processLogInUser() {
            // construct form with user data
            let formData = new FormData();
            for (let key in this.user) {
                formData.append(key, this.user[key]);
            }

            // get access token and user information
            let response = await fetch("https://gpt-tools-00ax.onrender.com/api/v1/auth/login", {
                method: "POST",
                mode: "cors",
                credentials: "include",
                body: formData
            });

            if (response.status == 200) {
                response = await response.json();

                response = await fetch("https://gpt-tools-00ax.onrender.com/api/v1/users/myself", {
                    method: "GET",
                    mode: "cors",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    }
                });

                response = await response.json();

                // construct data for load user information in home page
                let dataLogIn = {
                    username: this.user.username,
                    userId: response.id,
                    subscription: response.subscription,
                    availableServices: response.permissions,
                    role: response.role
                };

                this.$emit('completedLogIn', dataLogIn);
            } else {
                this.$router.push({ name: "logIn" });
                alert("Bad credentials. Try again.");
            }
        }

    }
}
</script>


<style>
* {
    box-sizing: border-box;
}

.login-card,
.wrapper {
    height: 100%;
}

@keyframes rotate {
    100% {
        background-position: 0% 50%;
    }
}

.login-card {
    display: grid;
    place-items: center;
    margin: 0;
    background-size: 200vh;
    font-family: 'Poppins', sans-serif;
    color: var(--text-color);
    animation: rotate 5s infinite alternate linear;
}

button {
    background: transparent;
    border: 0;
    cursor: pointer;
}

.control {
    border: 0;
    border-radius: 8px;
    outline: none;
    width: 100%;
    height: 56px;
    padding: 0 16px;
    background: transparent;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #5a4f79;
    margin: 8px 0;
    font-family: inherit;
    text-align: left;
    font-size: 16px;
    transition: 0.4s;
}

.login-card {
    margin: 0 auto;
    width: 400px;
    padding: 55px 30px 50px;
    border-radius: 1.25rem;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    backdrop-filter: blur(10px);
}

.login-card>h2 {
    font-size: 36px;
    font-weight: 600;
    margin: 0 0 6px;
    color: #f9f9f9;
}

.login-card img {
    width: 120px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 5px;
}

.login-card>h3 {
    color: #837f90;
    margin: 0 0 40px;
    font-weight: 500;
    font-size: 1rem;
}

.login-form {
    width: 100%;
    margin: 0;
    display: grid;
}

.login-form input.control::placeholder {
    color: #aaa7bc;
}

.login-form>button.control {
    cursor: pointer;
    width: 100%;
    height: 56px;
    padding: 0 16px;
    background: #f9f9f9;
    color: #000000;
    border: 0;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    letter-spacing: 2px;
    transition: all 0.375s;
}

.username {
    position: relative;
}

@keyframes spin {
    100% {
        rotate: 1turn;
    }
}

.spinner {
    position: absolute;
    top: 50%;
    right: 16px;
    translate: 0 -50%;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 3px solid #ccc9e1;
    border-top-color: #8f44fd;
    opacity: 0;
    animation: spin 1s infinite linear;
}

.spinner.visible {
    opacity: 1;
}
</style>

