import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'

import LogIn from './components/LogIn.vue'
import Home from './components/Home.vue'

import LangDetection from '@/components/gpt/LangDetection.vue'
import Translate from '@/components/gpt/Translate.vue'
import Sentiment from '@/components/gpt/Sentiment.vue'
import Intent from '@/components/gpt/Intent.vue'
import Summarize from '@/components/gpt/Summarize.vue'
import Formalize from '@/components/gpt/Formalize.vue'

import Register from '@/components/users/Register.vue'
import UserList from '@/components/users/List.vue'
import Edit from '@/components/users/Edit.vue'

import ServiceList from '@/components/services/List.vue'
import EditService from '@/components/services/Edit.vue'

import HistoricalList from '@/components/tracking/List.vue'

const routes = [{
  path: '/',
  name: 'root',
  component: App
}, {
  path: '/login',
  name: 'logIn',
  component: LogIn
}, {
  path: '/home',
  name: 'home',
  component: Home
}, {
  path: '/detection',
  name: 'detection',
  component: LangDetection
}, {
  path: '/translate',
  name: 'translate',
  component: Translate
}, {
  path: '/sentiment',
  name: 'sentiment',
  component: Sentiment
}, {
  path: '/intent',
  name: 'intent',
  component: Intent
},
{
  path: '/summarize',
  name: 'summarize',
  component: Summarize
}, {
  path: '/formalize',
  name: 'formalize',
  component: Formalize
}, {
  path: '/register',
  name: 'register',
  component: Register
}, {
  path: '/user-list',
  name: 'user-list',
  component: UserList
}, {
  path: '/edit/:userId',
  name: 'edit',
  component: Edit
}, {
  path: '/service-list',
  name: 'service-list',
  component: ServiceList
}, {
  path: '/edit-service/:serviceId',
  name: 'edit-service',
  component: EditService
}, {
  path: '/historical',
  name: 'historical',
  component: HistoricalList
}
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
