<template>
    <div class="col-sm-12 card column" id="tab1">
        <form class="form-inline" v-on:submit.prevent="updateProcess" id="form2" action="/version" method="post">
            <div class="row">
                <div style="text-align: start; padding:10px;">
                    <h2>Update</h2><br>
                    <p>Please fill in this form to update an account.</p>
                    <hr>
                </div>

                <div class="col-sm-12">
                    <h4>Basic user information</h4><br>

                    <label for="user">Username:</label>
                    <input type="text" name="user" class="form-control"
                        style="background-color:#484848; color: #fff; width: 100%;" v-model="input.newUser" required
                        placeholder="Enter username"><br>

                    <label for="pass">Password:</label>
                    <input type="text" name="pass" class="form-control"
                        style="background-color:#484848; color: #fff; width: 100%;" v-model="input.newPass"
                        placeholder="Enter password"><br>

                    <label for="role">Choose role:</label>
                    <select class="form-control" name="role" v-model="input.newRole">
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                    </select><br>

                    <label for="role">Choose subscription:</label>
                    <select class="form-control" name="role" v-model="input.newSubscription">
                        <option value="standard">Standard</option>
                        <option value="premium">Premium</option>
                    </select><br>
                    <hr>

                    <h4>Service activation and token assignment</h4><br>

                    <div class="d-flex">
                        <input class="form-check-input" type="checkbox" style="margin-right: 10%;" v-model="input.c1">
                        <label for="s1" style="width: 20%;">Language Detection</label>
                        <input type="number" name="s1" class="form-control" min="0"
                            style="background-color:#484848; color: #fff; width: 70%;"
                            v-if="input.newSubscription == 'standard'" v-model="input.v1" required
                            placeholder="Enter tokens"><br>
                    </div><br>

                    <div class="d-flex">
                        <input class="form-check-input" type="checkbox" style="margin-right: 10%;" v-model="input.c2">
                        <label for="s2" style="width: 20%;">Language Translation</label>
                        <input type="number" name="s2" class="form-control" min="0"
                            style="background-color:#484848; color: #fff; width: 70%;"
                            v-if="input.newSubscription == 'standard'" v-model="input.v2" required
                            placeholder="Enter tokens"><br>
                    </div><br>

                    <div class="d-flex">
                        <input class="form-check-input" type="checkbox" style="margin-right: 10%;" v-model="input.c3">
                        <label for="s3" style="width: 20%;">Sentiment Analysis</label>
                        <input type="number" name="s3" class="form-control" min="0"
                            style="background-color:#484848; color: #fff; width: 70%;"
                            v-if="input.newSubscription == 'standard'" v-model="input.v3" required
                            placeholder="Enter tokens"><br>
                    </div><br>

                    <div class="d-flex">
                        <input class="form-check-input" type="checkbox" style="margin-right: 10%;" v-model="input.c4">
                        <label for="s4" style="width: 20%;">Intent Detection</label>
                        <input type="number" name="s4" class="form-control" min="0"
                            style="background-color:#484848; color: #fff; width: 70%;"
                            v-if="input.newSubscription == 'standard'" v-model="input.v4" required
                            placeholder="Enter tokens"><br>
                    </div><br>

                    <div class="d-flex">
                        <input class="form-check-input" type="checkbox" style="margin-right: 10%;" v-model="input.c5">
                        <label for="s5" style="width: 20%;">Summarizer</label>
                        <input type="number" name="s5" class="form-control" min="0"
                            style="background-color:#484848; color: #fff; width: 70%;"
                            v-if="input.newSubscription == 'standard'" v-model="input.v5" required
                            placeholder="Enter tokens"><br>
                    </div><br>

                    <div class="d-flex">
                        <input class="form-check-input" type="checkbox" style="margin-right: 10%;" v-model="input.c6">
                        <label for="s6" style="width: 20%;">Writer</label>
                        <input type="number" name="s6" class="form-control" min="0"
                            style="background-color:#484848; color: #fff; width: 70%;"
                            v-if="input.newSubscription == 'standard'" v-model="input.v6" required
                            placeholder="Enter tokens"><br>
                    </div><br>
                    <hr>

                    <button type="submit" id="SendButton" name='Send2' value="Send2" class="btn btn-primary mb-2 mt-4"
                        style="width: 100%;"><i id="loaderSend2"></i>
                        <h5>Update</h5>
                    </button><br><br>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: "Edit",

    data: function () {
        return {
            id: '',
            input: {
                newUser: '',
                newPass: '',
                newRole: '',
                newSubscription: '',
                c1: false,
                v1: 0,
                c2: false,
                v2: 0,
                c3: false,
                v3: 0,
                c4: false,
                v4: 0,
                c5: false,
                v5: 0,
                c6: false,
                v6: 0
            },
        }
    },
    methods: {
        async getUserDetail() {
            let response = await fetch(`https://gpt-tools-00ax.onrender.com/api/v1/users/${this.id}`, {
                mode: "cors",
                credentials: "include",
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (response.status == 200) {
                response = await response.json();

                this.input.newUser = response.username;
                this.input.newRole = response.role;
                this.input.newSubscription = response.subscription;
            } else {

            }
        },

        async getUserServices() {
            let response = await fetch(`https://gpt-tools-00ax.onrender.com/api/v1/services/users/${this.id}`, {
                mode: "cors",
                credentials: "include",
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (response.status == 200) {
                response = await response.json();

                response.forEach(s => {
                    if (s.service_id == 1) {
                        this.input.c1 = true;
                        this.input.v1 = s.available_tokens;
                    } else if (s.service_id == 2) {
                        this.input.c2 = true;
                        this.input.v2 = s.available_tokens;
                    } else if (s.service_id == 3) {
                        this.input.c3 = true;
                        this.input.v3 = s.available_tokens;
                    } else if (s.service_id == 4) {
                        this.input.c4 = true;
                        this.input.v4 = s.available_tokens;
                    } else if (s.service_id == 5) {
                        this.input.c5 = true;
                        this.input.v5 = s.available_tokens;
                    } else if (s.service_id == 6) {
                        this.input.c6 = true;
                        this.input.v6 = s.available_tokens;
                    } else {

                    }
                });
            } else {

            }
        },

        async updateProcess() {
            let data = {};
            let services = [];
            let servicesToDelete = [];
            let tokensByService = [];

            if (this.input.c1) {
                services.push(1);
                tokensByService.push(this.input.v1);
            } else {
                servicesToDelete.push(1);
            }

            if (this.input.c2) {
                services.push(2);
                tokensByService.push(this.input.v2);
            } else {
                servicesToDelete.push(2);
            }

            if (this.input.c3) {
                services.push(3);
                tokensByService.push(this.input.v3);
            } else {
                servicesToDelete.push(3);
            }

            if (this.input.c4) {
                services.push(4);
                tokensByService.push(this.input.v4);
            } else {
                servicesToDelete.push(4);
            }

            if (this.input.c5) {
                services.push(5);
                tokensByService.push(this.input.v5);
            } else {
                servicesToDelete.push(5);
            }

            if (this.input.c6) {
                services.push(6);
                tokensByService.push(this.input.v6);
            } else {
                servicesToDelete.push(6);
            }

            data.username = this.input.newUser;

            if (this.input.newPass) {
                data.password = this.input.newPass;
            }

            data.role = this.input.newRole;
            data.subscription = this.input.newSubscription;
            data.services = services;
            data.services_to_delete = servicesToDelete;
            data.tokens_by_service = tokensByService;

            try {
                let response = await fetch(`https://gpt-tools-00ax.onrender.com/api/v1/users/as-admin/${this.id}`, {
                    mode: "cors",
                    credentials: "include",
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                });

                if (response.status == 200) {
                    alert("User successfully updated!");
                    this.getUserDetail();
                    this.getUserServices();
                } else if (response.status == 409) {
                    alert("Username already exists. Choose another username.");
                } else {

                }
            } catch (error) {
                alert("Error updating user. Please try again.");
            }
        }
    },

    beforeMount() {
        this.id = this.$route.params.userId;
        this.getUserDetail();
        this.getUserServices();
    }
}
</script>

<style>
* {
    color: var(--text-color);
}

.card {
    background-color: #323232;
    box-shadow: 1px 1px 5px rgb(255, 255, 255);
    text-align: left;
}

textarea {
    background-color: #484848 !important;
    border: 1px solid;
    color: #fff !important;

}

.column {
    width: 80%;
    margin: 0px auto;
}

/* Media query for small screens */
@media screen and (max-width: 500px) {
    .column {
        width: 100%;
        display: block;
        margin: 10px 20px;
        right: 20px;
    }
}

.row {
    margin: 0 0px 13px 0px;
}

.row:after {
    display: table;
    clear: both;
}

.btn {
    background: #5c759e;
    border-color: #fff;
    border-radius: 50px;
    font-size: small;
    font-weight: bold;
}
</style>