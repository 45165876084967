<template>
    <div class="col-sm-12 card column" id="tab1">
        <div class="row">
            <h2>Historical</h2><br>
            <hr>

            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Id</th>
                        <th scope="col">User</th>
                        <th scope="col">Service</th>
                        <th scope="col">Consumed Tokens</th>
                        <th scope="col">Price</th>
                        <th scope="col">Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in historicalDetail">
                        <th scope="row">{{ item.id }}</th>
                        <td>{{ item.username }}</td>
                        <td>{{ item.service_name }}</td>
                        <td>{{ item.consumed_tokens }}</td>
                        <td>{{ item.price }}$</td>
                        <td>{{ item.insertion_date }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "HistoricalList",

    data: function () {
        return {
            historicalDetail: [],
        }
    },

    methods: {
        async getHistoricalList() {
            let role = localStorage.getItem("role");
            let id = localStorage.getItem("userId");

            let url = "";

            if (role == "admin") {
                url = "https://gpt-tools-00ax.onrender.com/api/v1/tracker/historical";
            } else {
                url = `https://gpt-tools-00ax.onrender.com/api/v1/tracker/historical/user/${id}`;
            }

            let response = await fetch(url, {
                mode: "cors",
                credentials: "include",
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (response.status == 200) {
                response = await response.json();
                this.historicalDetail = response.historical;
            } else {

            }
        }
    },

    beforeMount() {
        this.getHistoricalList();
    }
}
</script>

<style>
* {
    color: var(--text-color);
}

.card {
    background-color: #323232;
    box-shadow: 1px 1px 5px rgb(255, 255, 255);
    text-align: left;
}

textarea {
    background-color: #484848 !important;
    border: 1px solid;
    color: #fff !important;

}

.column {
    width: 80%;
    margin: 0px auto;
}

/* Media query for small screens */
@media screen and (max-width: 500px) {
    .column {
        width: 100%;
        display: block;
        margin: 10px 20px;
        right: 20px;
    }
}

.row {
    margin: 0 0px 13px 0px;
}

.row:after {
    display: table;
    clear: both;
}

.btn {
    background: #5c759e;
    border-color: #fff;
    border-radius: 50px;
    font-size: small;
    font-weight: bold;
}
</style>