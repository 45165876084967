<template>
  <div id="app" class="app">
    <header>
      <a href="#" class="logo"><img src="@/assets/logo.png" width="45" /></a>

      <nav class="menu" v-if="isAuth">
        <ul class="menu-list">
          <li><a href="#" @click="loadHome">Home</a></li>
          <li>
            <a href="#">Services</a>
            <ul class="submenu">
              <li><a href="#" v-if="services.includes(1)" @click="loadDetection">Language Detection</a></li>
              <li><a href="#" v-if="services.includes(2)" @click="loadTranslate">Language Translation</a></li>
              <li><a href="#" v-if="services.includes(3)" @click="loadSentiment">Sentiment Analysis</a></li>
              <li><a href="#" v-if="services.includes(4)" @click="loadIntent">Intent Detection</a></li>
              <li><a href="#" v-if="services.includes(5)" @click="loadSummarize">Summarizer</a></li>
              <li><a href="#" v-if="services.includes(6)" @click="loadFormalize">Writer</a></li>
            </ul>
          </li>
          <li v-if="role == 'admin'">
            <a href="#">Admin Panel</a>
            <ul class="submenu">
              <li>
                <a href="#">Users</a>
                <ul class="submenu">
                  <li><a href="#" @click="loadRegister">Register</a></li>
                  <li><a href="#" @click="loadUserList">Update</a></li>
                </ul>
              </li>
              <li><a href="#" @click="loadServiceList">Services</a></li>
            </ul>
          </li>
          <li><a href="#" @click="loadHistorical">Historical</a></li>
        </ul>
      </nav>

      <div class="main">
        <a href="#" class="user" v-if="!isAuth"><i class="bx bxs-user"></i>Login</a>
        <a href="#" class="user" v-on:click="logOut" v-if="isAuth"><i class="bx bx-user-x"></i>Logout</a>
        <div class="bx bx-menu" id="menu-icon" v-on:click="toggleMenu" v-if="isAuth"></div>
      </div>
    </header>

    <div class="main-component">
      <router-view v-on:completedLogIn="completedLogIn">
      </router-view>
    </div>

    <footer class="page-footer">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <p>GPT Tools 2023 &copy;</p>
          </div>
          <div class="col-sm-6">
            <div class="socials">
              <a class="social-item" href="#"><i class='bx bxl-facebook'></i></a>
              <a class="social-item" href="#"><i class='bx bxl-google'></i></a>
              <a class="social-item" href="#"><i class='bx bxl-github'></i></a>
              <a class="social-item" href="#"><i class='bx bxl-twitter'></i></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",

  data: function () {
    return {
      isAuth: false,
      role: '',
      services: '',
      nameServices: '',
      tab: ''
    };
  },

  methods: {
    verifyAuth: function () {
      this.isAuth = localStorage.getItem("isAuth") || false;
      this.tab = localStorage.getItem("tab") || 'home';
      this.services = localStorage.getItem("services") || [];
      this.role = localStorage.getItem("role") || "user";

      if (this.isAuth == false) {
        this.$router.push({ name: "logIn" });
      } else {
        this.$router.push({ name: this.tab });
      }
    },
    loadLogIn: function () {
      this.$router.push({ name: "logIn" });
    },
    loadHome: function () {
      localStorage.setItem("tab", 'home');
      this.verifyAuth();
    },
    loadDetection: function () {
      localStorage.setItem("tab", 'detection');
      this.verifyAuth();
    },
    loadTranslate: function () {
      localStorage.setItem("tab", 'translate');
      this.verifyAuth();
    },
    loadSentiment: function () {
      localStorage.setItem("tab", 'sentiment');
      this.verifyAuth();
    },
    loadIntent: function () {
      localStorage.setItem("tab", 'intent');
      this.verifyAuth();
    },
    loadSummarize: function () {
      localStorage.setItem("tab", 'summarize');
      this.verifyAuth();
    },
    loadFormalize: function () {
      localStorage.setItem("tab", 'formalize');
      this.verifyAuth();
    },
    loadRegister: function () {
      localStorage.setItem("tab", 'register');
      this.verifyAuth();
    },
    loadUserList: function () {
      localStorage.setItem("tab", 'user-list');
      this.verifyAuth();
    },
    loadServiceList: function () {
      localStorage.setItem("tab", 'service-list');
      this.verifyAuth();
    },
    editUser: function () {
      localStorage.setItem("tab", 'edit');
      this.verifyAuth();
    },
    loadHistorical: function () {
      localStorage.setItem("tab", 'historical');
      this.verifyAuth();
    },
    async logOut() {
      // invalidate user credentials after login
      let response = await fetch("https://gpt-tools-00ax.onrender.com/api/v1/auth/logout", {
        mode: "cors",
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
      });

      localStorage.clear();
      this.verifyAuth();
    },
    completedLogIn: function (data) {
      this.services = data.availableServices;
      this.nameServices = data.nameServices;
      this.role = data.role;
      localStorage.setItem("isAuth", true);
      localStorage.setItem("tab", 'home');
      localStorage.setItem("username", data.username);
      localStorage.setItem("userId", data.userId);
      localStorage.setItem("role", data.role);
      localStorage.setItem("subscription", data.subscription);
      localStorage.setItem("services", data.availableServices);

      this.verifyAuth();
    },
    toggleMenu: function () {
      const menu = document.querySelector("#menu-icon");
      const navbar = document.querySelector(".menu");

      menu.classList.toggle("bx-x");
      navbar.classList.toggle("open");
    },

  },

  created: function () {
    this.verifyAuth();
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  text-decoration: none !important;
  list-style: none;
}

:root {
  --bg-btn-color: #5c759e;
  --bg-card: rgba(0, 0, 0, 0.5);
  --bg-color: #222327;
  --text-color: #fff;
  --main-color: #29fd53;
}

body {
  min-height: 100vh;
  background: var(--bg-color);
  color: var(--text-color);
}

header {
  position: relative;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  padding: 28px 12%;
  transition: all 0.5s ease;
}

.logo {
  display: flex;
  align-items: center;
}

.logo i {
  color: var(--main-color);
  font-size: 28px;
  margin-right: 3px;
}

.logo span {
  color: var(--text-color);
  font-size: 1.7rem;
  font-weight: 600;
}

.menu {
  background-color: transparent;
  padding: 10px;
}

.menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu ul li {
  display: inline-block;
  position: relative;
}

.menu ul li a {
  display: block;
  color: #fff;
  font-size: 1.1rem;
  padding: 10px 20px;
  text-decoration: none;
}

.menu ul li:hover>a {
  color: var(--main-color);
}

.menu .submenu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background-color: var(--bg-color);
  box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.1);
  min-width: 200px;
}

.menu .submenu ul {
  top: 0;
  left: 100%;
}

.menu .submenu li {
  display: block;
}

.menu ul li:hover>.submenu {
  display: block;
}

.main {
  display: flex;
  align-items: center;
}

.main a {
  margin-right: 25px;
  margin-left: 10px;
  color: var(--text-color);
  font-size: 1.1rem;
  font-weight: 500;
  transition: all 0.5s ease;
}

.user {
  display: flex;
  align-items: center;
}

.user i {
  color: var(--main-color);
  font-size: 28px;
  margin-right: 7px;
}

.main a:hover {
  color: var(--main-color);
}

@media screen and (max-width: 768px) {
  .menu ul li {
    display: block;
  }

  .menu .submenu {
    position: static;
    display: none;
    background-color: transparent;
  }

  .menu .submenu ul {
    position: static;
    display: none;
  }
}

#menu-icon {
  font-size: 35px;
  color: var(--text-color);
  cursor: pointer;
  z-index: 10001;
  display: none;
}

@media (max-width: 768px) {
  #menu-icon {
    display: block;
  }

  .menu {
    position: absolute;
    top: 100%;
    right: -100%;
    width: 270px;
    height: auto;
    background: var(--bg-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    box-shadow: 0px 4px 8px 0px rgba(255, 255, 255, 0.1);
    transition: all 0.5s ease;
  }

  .menu li {
    display: block;
    margin: 12px 0;
    padding: 0px 25px;
    transition: all 0.5s ease;
  }

  .menu a:hover {
    color: var(--text-color);
    transform: translatey(5px);
  }

  .menu a.active {
    color: var(--text-color);
  }

  .menu.open {
    right: 2%;
  }
}

.page-footer .row {
  height: 110px;
}

.page-footer p {
  margin-bottom: 0;
}

.page-footer .socials {
  max-width: 210px;
  margin-left: auto;
}

.page-footer .socials .social-item {
  margin-left: auto;
}

@media (max-width: 767.98px) {
  .page-footer {
    padding: 20px 0;
  }

  .page-footer .row {
    text-align: center;
  }

  .page-footer .row .socials {
    margin: auto;
  }
}

.align-items-center {
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
</style>
