<template>
  <div class="header" id="home">

    <div class="container">
      <div class="infos">
        <h6 class="subtitle">Welcome to AIWriter</h6>
        <h6 class="title"> {{ username }} </h6>
        <p>You are a {{ subscription }} member, it's wonderful.</p>

        <div class="buttons pt-3">
          <!-- <button class="btn btn-primary rounded">ADICIONAL SERVICES</button> -->
          <!-- <button class="btn btn-dark rounded">DOWNLOAD YOUR DATA</button> -->
        </div>
      </div>

      <div class="img-holder">
        <img src="@/assets/IA.png" alt="">
      </div>

    </div>

    <div class="widget">
      <div class="widget-item">
        <h2>{{ consumedTokens }}</h2>
        <p>Consumed tokens</p>
      </div>
      <div class="widget-item">
        <h2>{{ consumedBalance }}$</h2>
        <p>Consumed balance</p>
      </div>
      <div class="widget-item" v-if="availableTokens">
        <h2>{{ availableTokens }}</h2>
        <p>Available tokens</p>
      </div>
      <div class="widget-item" v-if="availableBalance">
        <h2>{{ availableBalance }}$</h2>
        <p>Available balance</p>
      </div>
      <div class="widget-item">
        <h2>{{ totalServices }}</h2>
        <p>Total services</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",

  data: function () {
    return {
      username: localStorage.getItem('username') || null,
      subscription: localStorage.getItem('subscription') || null,
      consumedTokens: "",
      consumedBalance: "",
      totalServices: localStorage.getItem("services").split(",").length || 0,
      availableTokens: "",
      availableBalance: ""
    }
  },

  methods: {
    async getUserBalance() {
      // get user historical
      let userId = localStorage.getItem('userId');
      let response = await fetch(`https://gpt-tools-00ax.onrender.com/api/v1/tracker/historical/user/${userId}`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (response.status == 200) {
        response = await response.json();
        this.consumedTokens = response.summary[0].consumed_tokens;
        this.consumedBalance = response.summary[0].consumed_balance;
        this.availableTokens = response.summary[0].available_tokens;
        this.availableBalance = response.summary[0].available_balance;
      } else if (response.status == 401) {
        alert("Your session has expired. Please login again.");
        localStorage.clear();
        this.$root.verifyAuth();
        this.$router.push({ name: "logIn" });
      } else {

      }
    }
  },
  beforeMount() {
    this.getUserBalance();
  }
}
</script>

<style>
.header {
  display: block;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.header .container .infos {
  margin-top: 50px;
}

.header .container .infos .subtitle {
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 500;
}

.header .container .infos .title {
  margin-bottom: 0px;
  font-size: 74px;
  font-weight: bold;
}

.header .container .infos p {
  margin-top: -5px;
  font-size: 22px;
}

.header .container .infos .btn {
  display: inline-block;
  margin: 0 2px;
}

@media (max-width: 991.98px) {
  .header .container {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .header .container .infos {
    margin-top: 0;
  }

  .header .container .img-holder {
    display: none;
  }
}

.subtitle {
  font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1200 - 320)));
  opacity: .8;
}

@media (min-width: 992px) {
  .subtitle {
    font-size: 15px;
  }
}

.testmonial-card .title {
  margin-top: 30px;
  font-size: 20px;
}

.title {
  font-size: calc(18px + (25 - 18) * ((100vw - 320px) / (1200 - 320)));
}

@media (min-width: 992px) {
  .title {
    font-size: 28px;
  }
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #495057;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.65rem 0.9rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 14px;
  font-weight: bold;
  min-width: 120px;
  display: inline-block;
  margin: 0 2px;
  position: relative;
  top: -1px;

}

.btn-primary {
  background-color: var(--bg-btn-color);
  border-color: var(--bg-btn-color);
}

.btn-primary:hover {
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.rounded {
  border-radius: 1.5rem !important;
}

.btn.btn-primary {
  color: #fff;
}

.btn-dark {
  color: #fff;
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.btn-dark:hover {
  color: #fff;
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.socials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.socials .social-item {
  display: block;
  margin: 0 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-decoration: none;
}

.socials .social-item:hover {
  background: var(--main-color);
  color: #fff;
}

.page-footer .socials {
  max-width: 210px;
  margin-left: auto;
}

.page-footer .socials .social-item {
  margin-left: auto;
}

@media (max-width: 767.98px) {
  .page-footer {
    padding: 20px 0;
  }

  .page-footer .row {
    text-align: center;
  }

  .page-footer .row .socials {
    margin: auto;
  }
}

.mt-4 {
  margin-top: 1.5rem !important;
}

img {
  vertical-align: middle;
  border-style: none;
}

.header .container .img-holder {
  height: 100%;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding-left: 0px;
}

.header .container .img-holder img {
  width: 50%;
  max-width: 620px;
  -webkit-filter: drop-shadow(0 -4px 20px rgba(206, 212, 218, 0.4));
  filter: drop-shadow(0 -4px 20px rgba(206, 212, 218, 0.4));
  margin-left: auto;
  margin-right: 2rem;
}

.header .container {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header .widget {
  position: relative;
  max-width: 650px;
  width: 95%;
  margin: 0 auto;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  border: 1px solid #dee2e6;
  padding: 20px 0;
  margin-top: 40px;
  z-index: 999;
  box-shadow: 0 0 10px rgba(173, 181, 189, 0.4);
}

.header .widget .widget-item {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  text-align: center;
  border-right: 1px solid #dee2e6;
}

.header .widget .widget-item:last-child {
  border-right: 0;
}

.header .widget .widget-item h2,
.header .widget .widget-item p {
  margin: 0;
}

.header .widget .widget-item p {
  opacity: .7;
  font-size: 14px;
}

@media (max-width: 767.98px) {
  .header .widget {
    padding: 10px;
    margin-top: 30px;
  }

  .header .widget .widget-item h2 {
    font-size: 20px;
  }

  .header .widget .widget-item p {
    font-size: 13px;
  }
}
</style>