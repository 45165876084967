<template>
    <div class="col-sm-12 card column" id="tab1">
        <form class="form-inline" v-on:submit.prevent="translateProcess" id="form2" action="/version" method="post">
            <div class="row">
                <h5 style="text-align: center; padding:35px 0px;">
                    <p>Language Translation</p>
                </h5>
                <div class="col-sm-6">
                    <select class="form-control" v-model="req.source">
                        <option value="english">English</option>
                        <option value="french">French</option>
                        <option value="spanish">Spanish</option>
                    </select><br>
                    <label for="Textarea1">Enter your text</label>
                    <textarea class="form-control smm" name="Summary" id="Textarea1" rows="7" v-model="req.sentence"
                        required></textarea>
                    <button type="submit" id="SendButton" name='Send2' value="Send2" class="btn btn-primary mb-2 mt-4"><i
                            id="loaderSend2"></i> Send</button>
                    <button @click="reset()" type="button" id="ResetButton" name='Reset2' value="Reset2"
                        class="btn btn-primary mb-2 mt-4">Reset</button>
                </div>

                <div class="col-sm-6">
                    <select class="form-control" v-model="req.target">
                        <option value="english">English</option>
                        <option value="french">French</option>
                        <option value="spanish">Spanish</option>
                    </select><br>
                    <label for="result">Result<span class="text-white"> (*)</span></label>
                    <textarea class="form-control" name="Points" id="result" rows="7" readonly
                        style="background-color:#484848; color: #fff;" v-model="req.answ"></textarea>
                    <button @click="copied('result')" type="button" class="btn btn-primary mb-2 mt-4"><i
                            class='bx bx-copy'></i></button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: "Translate",

    data: function () {
        return {
            req: {
                sentence: '',
                source: 'english',
                target: 'spanish',
                answ: ''
            },
        }
    },
    methods: {

        copied(element) {
            var copyText = document.getElementById(element);
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);
        },
        reset() {
            this.req.sentence = ''
            this.req.answ = ''
        },

        async translateProcess() {
            let response = await fetch("https://gpt-tools-00ax.onrender.com/api/v1/services/gpt-3/lang-translation", {
                mode: "cors",
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(this.req)
            });
            if (response.status == 200) {
                response = await response.json();
                this.req.answ = response.choices[0].text.replace(/^\n+/, '');
            } else if (response.status == 402) {
                alert("You do not have enough tokens available.");
            } else if (response.status == 413) {
                alert("Maximum capacity of tokens per request exceeded.");
            } else if (response.status == 409) {
                alert("The service was deactivated.");
            }
            else if (response.status == 401) {
                alert("Your session has expired. Please login again.");
                localStorage.clear();
                this.$root.verifyAuth();
                this.$router.push({ name: "logIn" });
            } else {

            }
        }
    },

    created: function () {
    },
}
</script>

<style>
* {
    color: var(--text-color);
}

.card {
    background-color: #323232;
    box-shadow: 1px 1px 5px rgb(255, 255, 255);
    text-align: left;
}

textarea,
select {
    background-color: #484848 !important;
    border: 1px solid;
    color: #fff !important;

}

.column {
    width: 80%;
    margin: 0px auto;
}

/* Media query for small screens */
@media screen and (max-width: 500px) {
    .column {
        width: 100%;
        display: block;
        margin: 10px 20px;
        right: 20px;
    }
}

.row {
    margin: 0 0px 13px 0px;
}

.row:after {
    display: table;
    clear: both;
}

.btn {
    background: #5c759e;
    border-color: #fff;
    border-radius: 50px;
    font-size: small;
    font-weight: bold;
}
</style>