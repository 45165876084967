<template>
    <div class="col-sm-12 card column" id="tab1">
        <div class="row">
            <h2>Services list</h2><br>
            <p>Select the service you want to edit.</p>
            <hr>

            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Family</th>
                        <th scope="col">Creation Date</th>
                        <th scope="col">Activate</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in servicesDetail">
                        <th scope="row">{{ item.id }}</th>
                        <td>{{ item.name }}</td>
                        <td>{{ item.family }}</td>
                        <td>{{ item.created_date }}</td>
                        <td>{{ item.is_active }}</td>
                        <td>
                            <a href="#" @click="editProcess(item.id)">Edit</a><br>
                            <!-- <a href="#" @click="activationProcess(item.id, item.is_active)">Activate/Deactivate</a> -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "ServiceList",

    data: function () {
        return {
            servicesDetail: [],
        }
    },

    methods: {
        async getServiceList() {
            let response = await fetch("https://gpt-tools-00ax.onrender.com/api/v1/services/", {
                mode: "cors",
                credentials: "include",
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (response.status == 200) {
                response = await response.json();
                this.servicesDetail = response;
            } else {

            }
        },
        editProcess(id) {
            this.$router.push({ name: "edit-service", params: { serviceId: id } });
        }
    },

    beforeMount() {
        this.getServiceList();
    }
}
</script>

<style>
* {
    color: var(--text-color);
}

.card {
    background-color: #323232;
    box-shadow: 1px 1px 5px rgb(255, 255, 255);
    text-align: left;
}

textarea {
    background-color: #484848 !important;
    border: 1px solid;
    color: #fff !important;

}

.column {
    width: 80%;
    margin: 0px auto;
}

/* Media query for small screens */
@media screen and (max-width: 500px) {
    .column {
        width: 100%;
        display: block;
        margin: 10px 20px;
        right: 20px;
    }
}

.row {
    margin: 0 0px 13px 0px;
}

.row:after {
    display: table;
    clear: both;
}

.btn {
    background: #5c759e;
    border-color: #fff;
    border-radius: 50px;
    font-size: small;
    font-weight: bold;
}
</style>